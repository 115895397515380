import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';

export default function LaCantine() {
  return (
    <div className="p-5 mx-auto sm:p-10 md:p-16 bg-coolGray-100 text-gray-800">
      <div className="max-w-xl mb-10 md:mx-auto sm:text-center lg:max-w-2xl md:mb-12 text-center">
        <div class="max-w-xl mb-10 md:mx-auto sm:text-center lg:max-w-2xl md:mb-12">
          <h2 class="max-w-lg mb-6 font-sans text-3xl font-bold leading-none tracking-tight text-gray-900 sm:text-4xl md:mx-auto text-center">
            <span class="relative inline-block text-blue-900">
              La Cantine
            </span>
          </h2>
          <p class="text-base text-gray-700 md:text-lg">
            Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque
            rem aperiam, eaque ipsa quae.
          </p>
          <div class="flex mt-6 justify-center">
            <div class="w-16 h-1 rounded-full bg-blue-700 inline-flex"></div>
          </div>
        </div>
      </div>
      <div className="flex flex-col max-w-3xl mx-auto overflow-hidden rounded">
        <StaticImage
          src="../images/cantine.webp"
          alt=""
          className="w-full h-60 sm:h-96 bg-coolGray-500"
        />

        <div className="p-6 pb-12 m-4 mx-auto -mt-16 space-y-6 lg:max-w-2xl sm:px-10 sm:mx-12 lg:rounded-md bg-white shadow-lg z-10">
          <div className="text-coolGray-800">
            <p>
              Le Collège Français de Kairouan (CFK) dispose d’un espace équipé dédié a la
              restauration. L’établissement proposera tout au long de l’année des repas chauds et
              équilibres afin d’assurer le bienêtre de nos élèves.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
